.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-slide-active {
    opacity: 1;
  }

 
  